.detail_new {
  height: 100%;
  width: 100%;
  font-family: PingFangSC-Semibold, PingFang SC; }
  .detail_new .header {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    padding: 2.66667vw;
    background: #FFFFFF; }
    .detail_new .header .back {
      width: 5.33333vw;
      height: 5.33333vw;
      background: url("/help_new/icon_back1.png") no-repeat;
      background-size: 100% 100%;
      position: absolute;
      left: 2.66667vw; }
    .detail_new .header .title {
      font-size: 5.06667vw;
      color: #121E36; }
  .detail_new .content {
    min-height: 100vh;
    background: #F8F9FB; }
    .detail_new .content .detail {
      background: #FFFFFF;
      box-shadow: 0px 0px 6.66667vw 0px rgba(2, 24, 51, 0.12);
      border-radius: 2.66667vw;
      margin: 0 4.26667vw;
      padding: 4vw;
      transform: translateY(5.33333vw); }
      .detail_new .content .detail .detail_header {
        border-bottom: 1px dashed #e1e6ef;
        font-weight: 600;
        font-size: 5.86667vw; }
        .detail_new .content .detail .detail_header .icon {
          color: #2467E2; }
        .detail_new .content .detail .detail_header .title {
          color: #111E36; }
      .detail_new .content .detail .detail_content {
        font-size: 4vw;
        color: #111E36;
        margin-top: 2.66667vw;
        font-family: PingFangSC-Regular, PingFang SC; }
      .detail_new .content .detail .detail_footer {
        margin-top: 5.33333vw;
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: #878C9C;
        font-size: 4vw; }
        .detail_new .content .detail .detail_footer .agreeOrNo {
          display: flex; }
          .detail_new .content .detail .detail_footer .agreeOrNo .agree_item {
            transform: skew(15deg);
            display: flex;
            justify-content: center;
            align-items: center; }
          .detail_new .content .detail .detail_footer .agreeOrNo .agree {
            color: #666D7F;
            background: #EFF1F7;
            padding: 0.53333vw 4vw;
            border-top-left-radius: 5.33333vw;
            border-bottom-left-radius: 9.06667vw;
            transform: skew(-15deg);
            margin-right: 2.66667vw; }
            .detail_new .content .detail .detail_footer .agreeOrNo .agree .agree_icon {
              width: 4vw;
              height: 4vw;
              background: url("/help_new/icon_agree.png");
              background-size: 100%;
              margin-right: 1.06667vw; }
          .detail_new .content .detail .detail_footer .agreeOrNo .notAgree {
            color: #666D7F;
            background: #EFF1F7;
            padding: 0.53333vw 4vw;
            border-top-right-radius: 9.06667vw;
            border-bottom-right-radius: 5.33333vw;
            transform: skew(-15deg); }
            .detail_new .content .detail .detail_footer .agreeOrNo .notAgree .agree_icon {
              width: 4vw;
              height: 4vw;
              background: url("/help_new/icon_notagree.png");
              background-size: 100%;
              margin-right: 1.06667vw; }
          .detail_new .content .detail .detail_footer .agreeOrNo .active {
            color: #2064DA;
            background: #ECF6FE; }
